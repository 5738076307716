import type { Unit } from 'types/unit';

import config from 'configs/app';

const weiName = config.chain.currency.weiName || 'aulr(wei)';

export const currencyUnits: Record<Unit, string> = {
  wei: weiName,
  gwei: `nULR(Gwei)`,
  ether: config.chain.currency.symbol || 'ULR',
};
